.modal{
  position:fixed;
  left:0; right:0;
  bottom:0; top:0;
  width:500px;
  height:300px;
  background-color:white;
  z-index: 200;
  margin:auto;
  border:1px solid #5688be;
  text-align: left;
  display: flex;
  flex-direction:column;
  max-width:95%;
}

.modal > b{
  background-color:#5688be;
  color:white;
  padding:5px;
  width:100%;
}

.modal .close-button{
  cursor:pointer;
  position:absolute;
  width:20px;
  right:5px;
  top:-1px;
}

.modal form{
  display: flex;
  flex-direction:column;
  align-items: flex-start;
  padding:20px;
}
.modal input,
.modal textarea{
  font-size:13px;
  font-family: Verdana;
  margin-bottom:10px;
  padding:10px;
  width:100%;
}

.modal button{
  font-size:13px;
  font-family: Verdana;
  margin-bottom:10px;
  padding:10px 50px;
}