*{
  box-sizing:border-box
}
.App{
  text-align:center;
  padding-bottom:40px;
}

body{
  height:100vh;
  overflow:scroll;
  background-image: url('../public/bg.jpg');
  background-attachment: fixed;
  background-size: cover;
  font-family: Verdana;
  font-optical-sizing: auto;
  font-style: normal;
}
p,b,a{
  font-size:13px;
}


.container{
  background-color:white;
  color:black;
  max-width:1200px;
  display: flex;
  flex-wrap:nowrap;
  margin:auto;
  margin-top:50px;
}

.layout-half{
  width:60%;
  padding:20px;
  text-align:left;
}
.layout-half.smaller{
  width:40%;
}


.box{
  margin-bottom:40px;
}
.bio{
  display: flex;
  flex-direction:column;
}
.bio img{
  max-width:200px;
  /* margin-top:5px; */
}
.bio .online-now{
  max-width:100px;
}
.profile-picture{
  display: flex;
  padding-top:10px;
}
.profile-text{
  padding:0 20px;
}
.profile-text p{
  margin:0;
}
.profile-text .motto{
  margin-bottom:30px;
}
.last-login{
  margin-top:45px;
}

.the-list, .contact{
  border:1px solid #5688be;
  display: flex;
  flex-direction: column;
}

.the-list b, .contact b{
  background-color:#5688be;
  color:white;
  padding:5px 10px;
}

.the-list p{
  margin:0;
  padding:10px;
  display: flex;
  align-items:center;
}
.the-list a{
  display: flex;
  align-items:center;
  margin-left:5px;
}
.the-list img{
  max-width:20px;
  margin-left:5px;
}
.contact-methods{
  display: flex;
  flex-direction:row;
  flex-wrap:wrap;
  padding:0 10px;
}

.contact a,
.contact p{
  display: flex;
  align-items:center;
  width:50%;
  margin:10px 0;
  text-decoration: none;
  cursor:pointer;
  color:#0000EE;
}

.overlay{
  background-color:rgba(0,0,0,.3);
  position:fixed;
  top:0; left:0;
  width:100%;
  height:100%;
  z-index: 100;
}

.contact img{
  max-width:15px;
  margin-right:5px;
}
.splash-logo{
  opacity:0;
  max-width:50%;
  margin-top:20vh;
  transition:opacity .5s ease;
  animation: 2s ease-in .5s fadeIn;
  animation-fill-mode:forwards;
}

.network{
  border:1px solid black;
  text-align: center;
  padding:20px 0;
}

.blurbs, .friends, .videos{
  display: flex;
  flex-direction:column;
}
.blurbs > b,
.friends > b,
.videos > b{
  background-color:#fcc28b;
  color:#fc4f0b;
  padding:3px;
  margin-bottom:10px;
}
.blurbs.box{
  margin-bottom:20px;
}

.friends-list{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.friends-list a{
  display: flex;
  flex-direction:column;
  width:20%;
  text-decoration:none;
  font-weight: bold;
  text-align: center;
  margin:20px 10px 0;
}

.friends-list img{
  width:100%;
  height:100%;
  object-fit: cover;
}

h3{
  margin:0;
  opacity:0;
  font-size:60px;
  text-shadow: 8px 7px 19px #000000;
  color:white;
  font-weight:100;
  transition:opacity .5s ease;
  animation: 2s ease-in 2s fadeIn;
  animation-fill-mode:forwards;
  text-align: center;
}

iframe{
  aspect-ratio: 16 / 9;
}

@keyframes fadeIn {
  from {
    opacity:0;
  }

  to {
    opacity:1;
  }
}

@media only screen and (max-width: 700px) {
  h3{
    font-size:7vw;
  }
  .splash-logo{
    max-width:90%;
  }
  .container{
    flex-wrap:wrap;
  }
  .layout-half,
  .layout-half.smaller{
    width:100%;
  }
  html{
    padding-bottom:20px;
  }
  body{
    overflow:scroll;
    padding:0 10px;
    height:auto;
  }
  .friends-list a{
    width:40%;
  }
}